import React from "react";

export default function Hero({ section }) {
  return (
    <>
      <div className="blog-section-two pt-100">
        <div className="container">
          <div className="row">
            <div className="col-xxl-11 m-auto">
              <div className="row align-items-center mb-50 lg-mb-20">
                <div className="col-sm-6">
                  <div className="title-style-two">
                    <h3 className="title">{section.title}</h3>
                  </div>
                </div>
              </div>
              <div className="row gx-xl-5">
                <p className="preWrap">{section.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
