import React from "react";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";

export default function BlogSummary({ section }) {
  const { posts = [] } = section;

  return (
    <div className="blog-meta-wrapper pe-xxl-5">
      {posts.map((p, i) => (
        <>
          {i > 0 && (
            <div className="pt-4 pb-2">
              <hr />
            </div>
          )}
          <article className="blog-meta-five" key={p.date}>
            <div className="post-info">
              <span className="date">{new Date(p.date).toDateString().substring(4)}</span>
            </div>
            <div className="title mt-1 mb-1">{p.title}</div>
            {p.link && (
              <>
                {p.link?.startsWith("http") ? (
                  <a href={p.link} target="_blank" rel="noreferrer" className="btn btn-primary btn-sm">
                    more...
                  </a>
                ) : (
                  <Link to={p.link} className="btn btn-primary btn-sm">
                    more ...
                  </Link>
                )}
              </>
            )}
            <div className="img-meta position-relative mt-4">{p.image && <img src={p.image.url} alt="" className="w-100 border p-3" />}</div>
            <div className="pt-4">
              <ReactMarkdown linkTarget="_blank">{p.content}</ReactMarkdown>
            </div>
          </article>
        </>
      ))}
    </div>
  );
}
