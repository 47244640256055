import React from "react";

export default function Preloader() {
  return (
    <section>
      <div id="preloader">
        <div id="ctn-preloader" className="ctn-preloader">
          <div className="animation-preloader">
            <div className="icon">
              <img src="images/logo/vCamp.svg" alt="" className="m-auto d-block" /> <span></span>
            </div>
            <div className="txt-loading mt-4">
              <span data-text-preloader="H" className="letters-loading">
                H
              </span>
              <span data-text-preloader="E" className="letters-loading">
                E
              </span>
              <span data-text-preloader="A" className="letters-loading">
                A
              </span>
              <span data-text-preloader="D" className="letters-loading">
                D
              </span>
              <span data-text-preloader="L" className="letters-loading">
                L
              </span>
              <span data-text-preloader="E" className="letters-loading">
                E
              </span>
              <span data-text-preloader="S" className="letters-loading">
                S
              </span>
              <span data-text-preloader="S" className="letters-loading">
                S
              </span>
              <span data-text-preloader="H" className="letters-loading">
                H
              </span>
              <span data-text-preloader="O" className="letters-loading">
                O
              </span>
              <span data-text-preloader="S" className="letters-loading">
                S
              </span>
              <span data-text-preloader="T" className="letters-loading">
                T
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
