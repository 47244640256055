import React, { useState } from "react";

export default function CollaspeHero({ section = {} }) {
  const [actIndex, setActIndex] = useState(0);

  return (
    <div className="vcamp-text-block-five pt-50 mt-50 border-top lg-pt-100 lg-mt-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xxl-5 col-lg-6">
            <div className="text-wrapper">
              <div className="title-style-five">
                <div className="upper-title">{section.title}</div>
                <h2 className="title">{section.heading}</h2>
              </div>
              <p className="text-lg">{section.description}</p>
            </div>
          </div>
          <div className="col-xl-5 col-lg-6 ms-auto">
            <div className="accordion accordion-style-one md-mt-30" id="accordionOne">
              {section.features.map((f, i) => (
                <div className="accordion-item" key={f.heading}>
                  <div className="accordion-header" id="headingOne">
                    <button onClick={() => setActIndex(i)} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      {f.heading}
                    </button>
                  </div>
                  {i === actIndex && (
                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionOne">
                      <div className="accordion-body">
                        <p>{f.description}</p>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
